.profile-picture{
  width:25vw;
  height:25vw;
  border-radius:100%;
  border:20px solid #001d28;
}


/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
  .profile-picture {
    width:25vh;
    height:25vh;
  }
  
  .profile-picture-container{
    top:-4%;
  }
  
  .laius-presentation{
    font-size:0.90rem;
    z-index:0;
  }
  .small-device{
      .laius-presentation{
          padding:9.5rem 2.5rem 1.5rem 2rem;
      }
  }
  .waalaxy-logo-container{
    top:-15%;
    left:20%;
    z-index:10;
  }

  #waalaxy{
    .laius-presentation{
          padding:8rem 2.5rem 1.5rem 2rem;
    }
  }
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 1199.98px) {
  .profile-picture {
    width:25vw;
    height:25vw;
  }
  .small-device{
    .laius-presentation{
        padding:7rem 2.5rem 1.5rem 2rem;
        font-size:0.90rem;
        z-index:0;
    }
  }
 
  .profile-picture-container{
    top:-8%;
  }
  
  .waalaxy-logo-container{
    top:25%;
    left:-8%;
    z-index:10;
  }
  
  #waalaxy{
    .laius-presentation{
          padding:1.5rem 2.5rem 1.5rem 6rem;
    }
  }
}

@media(min-width:1200px){
    
  .profile-picture {
    width:25vh;
    height:25vh;
  }
  
  .laius-presentation{
    font-size:1.2rem;
  }
  .waalaxy-logo-container{
    top:20%;
    z-index:10;
  }
  
  .profile-picture-container{
    top:-4%;
  }
}


.profile-picture-container{
  position:relative;
  justify-content: center;
  .profile-picture-section{
    position: absolute;
    z-index: 10;
  }
}


.rounding-logo{
    
  width:32vh;
  height:32vh;
  border-radius:100%;
  border:20px solid red;
}

p,li,ul{
    margin:0;
}


.presentation-title{
    text-align: center;
    position:relative;
    font-family: "Montserrat", sans-serif;
    font-size:8vh;
    margin-bottom: 4vh;
}

.first-name{
    font-weight: 300;
}

.last-name{
    font-weight: 600;
}

.laius-text{
    text-align: justify;
}



.laius-presentation{
    background-color: rgb(248, 247, 247);
    border-radius: 20px;
    color:#001d28;
    font-family: "Montserrat", sans-serif;
    padding:2.5rem 2.5rem 2.5rem 2.5rem;
}

.laius-presentation.right{
    padding:2.5rem 2.5rem 2.5rem 9rem;
}

.laius-presentation.left{
    padding:2.5rem 9rem 2.5rem 2.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.profile-presentation{
    height:70%;
    position:relative;
    width:80%;
    display:flex;
    justify-content: center;
}
