.quiz-container{
    z-index:1;
    background-color: rgb(248, 247, 247);
    padding:2rem;
    border-radius:20px;
    color: #001d28;
}

.message-quiz{
    color:#198754
}