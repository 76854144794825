@keyframes slideFromMiddleToBottom {
  from {
    transform: translateY(-80%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.card-number.row.not-visible {
  animation: none;
}
.row.card-number {
    opacity: 0;
    animation: slideFromMiddleToBottom 0.5s ease-out forwards;
}

.row.card-number.delay-1 { animation-delay: 0.1s; }
.row.card-number.delay-2 { animation-delay: 0.2s; }
.row.card-number.delay-3 { animation-delay: 0.3s; }
.row.card-number.delay-4 { animation-delay: 0.4s; }
.row.card-number.delay-5 { animation-delay: 0.5s; }

.card-number {
  width:200px;
  height: 250px;
}
@media (max-width: 767.98px) {
  .card-number {
    width:100%;
    height:200px;
    overflow-wrap: break-word;
    text-wrap:wrap;
    padding:2rem;
  }
}