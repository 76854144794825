
body{
  background-color: #001d28 !important;
  color:white !important;
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  section {
    min-height: 100vh;
    display:inline;
  }
}
section{
    max-width: 100vw;
    justify-content: center;
    --bs-gutter-x: 0;
}

.title-card{
  font-size: 3rem;
  font-weight: 700;
}

.text-card{
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-size:1
}

.card-number{
  display:flex;
  justify-content:center;
  align-items: center;
  background-color: rgb(248, 247, 247);
  border-radius: 20px;
  color: #001d28;

}

.title{
  height:15rem;
}


a.primary{
   color: #001d28;
}

a.secondary{
  color : rgb(248, 247, 247);
}


.starfield {
  position: fixed; /* Fixe le composant en arrière-plan */
  top: 0;
  left: 0;
  width: 100vw; /* Prend toute la largeur de la vue */
  height: 100vh; /* Prend toute la hauteur de la vue */
  z-index:0;
  overflow: hidden;
}
@media (max-width: 1200px) {
  div.carousel-indicators{
    bottom:-40px;
  }
  a.carousel-control-prev>.carousel-control-prev-icon, a.carousel-control-next>.carousel-control-next-icon{
    background-color:#001d28!important;
    border-radius:50px;
    padding:20px 30px 20px 10px;
  }

}