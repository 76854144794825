.custom-card .card-img-top {
  transition: opacity 0.5s ease;
}

.custom-card{
  border-radius: 10px;
}
.custom-card:hover .card-body{
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  transition: opacity 1s ease, backdrop-filter 1s ease;
  border-radius: 10px;
}

.custom-card .card-body {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease, backdrop-filter 1s ease;
  width: 100%;
  text-align: center;
  border-radius: 10px;
}
.card-body{
  height:100%;
  border-radius: 10px;
}

.custom-card{
  cursor: pointer;
}

.custom-card:hover .card-body{
  opacity: 1;
}

.custom-card .card-title, .custom-card .card-type {
  font-weight: bold; /* Met le titre et le sous-titre en gras */
}

.custom-card .card-type {
  color: #666; /* Optionnel : change la couleur du sous-titre pour le différencier */
  font-style: italic;;
}

.custom-card .card-text {
  font-size: 0.9rem; /* Ajuste la taille de la police de la description si nécessaire */
}

.tech-icon{
  height:auto;
  max-width: 50px;
  border-radius: 100%;
}

.tech-icons{
  display:flex;
  flex-wrap: wrap;
  gap: 10px;
}

.img-container{
  min-width:400px;
  min-height:400px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.img-card{
  width:300px;
  height: 300px;
  opacity: 0.5;
  border-radius: 5px;
}

.progress-title{
  font-size: 1.2rem;
  font-weight: bold;
  color:rgb(190, 126, 6);
}


@media(max-width:992px){
  .custom-card.active .card-body{
    opacity: 1;
  }
  .custom-card.active .card-body{
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    transition: opacity 1s ease, backdrop-filter 1s ease;
    border-radius: 10px;
  }
}